<template>
  <div>
    <!-- Start Header Area -->
    <Header>
        <img slot="logo" src="../assets/images/logo/logo-symbol-light.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Najczęściej zadawane pytania</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Accordion Area  -->
    <div class="rn-accordion-area rn-section-gap bg_color--5">
      <v-container>
        <v-row>
          <v-col lg="12">
              <div class="section-title text-center mb--30">
                  <p>Zbiór najczęściej zadawanych pytań i odpowiedzi na nie.</p>
              </div>
            <FaqAccordion />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Accordion Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import FaqAccordion from "../components/accordion/FaqAccordion";
  import Footer from "../components/footer/Footer";

  export default {
    components: {
      Header,
      FaqAccordion,
      Footer,
    },
    data() {
      return {
        accordionContent: [],
        breadcrumbs: [
          {
              text: "Feldenkrais Polska",
              to: "/",
              disabled: false,
          },
        {
            text: "F.A.Q.",
            to: "/faq",
            disabled: true,
        },
        ],
      };
    }
  };
</script>
