<template>
  <div>
    <v-expansion-panels
      accordion
      flat
      v-model="panel"
      mandatory
      class="about-expension-panels"
    >
      <v-expansion-panel v-for="(item, i) in accordionContent" :key="i">
        <v-expansion-panel-header hide-actions>{{
          item.title
        }}</v-expansion-panel-header>
        <v-expansion-panel-content v-html="item.content">
          {{ item.content }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      panel: 0,
      accordionContent: [],
    };
  },
    mounted() {
        axios({
            url: process.env.VUE_APP_API_ENDPOINT,
            method: 'post',
            data: {
                query: `
                    query getAllFaqs
                    {
                        faqs {
                          question,
                          answer
                       }
                    }
                  `
            }
        })
            .then(response => {
                // console.log('RESP',  response.data.data.faqs);
                Array.from(response.data.data.faqs).forEach(item => {
                    this.accordionContent.push({
                        title: item.question,
                        content: item.answer
                    });
                });
            })
    },
};
</script>


<style scoped>
.v-expansion-panels--accordion > .v-expansion-panel {
    margin-top: 1.5em;
}
</style>
